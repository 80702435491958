import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/react-hooks";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import clientV3 from "./apolloClient";
import "@fontsource/roboto";
import "@fontsource/poppins";
import { isAuthenticated } from "./lib/auth.js";
import VConsole from "./assets/vconsole.min.js";
require("./utils/js-sdk.min.js");

const originalAddEventListener = EventTarget.prototype.addEventListener;
EventTarget.prototype.addEventListener = function (type, listener, options) {
  if (type === "touchstart" || type === "touchmove" || type === "wheel") {
    options = { passive: true, ...(options || {}) };
  }
  originalAddEventListener.call(this, type, listener, options);
};

if (window.location.href.includes("miniapp")) {
  new VConsole();
}

// Lazy load i18n.js
const i18nPromise = import("./i18next");

const root = ReactDOM.createRoot(document.getElementById("root"));

// Bugsnag setup
Bugsnag.start({
  logger: null,
  enabledReleaseStages: ["production"],
  releaseStage: process.env.REACT_APP_TRACK_RELEASE_STAGE,
  apiKey: process.env.REACT_APP_TRACK_TOKEN,
  plugins: [new BugsnagPluginReact()],
});

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const AppWrapper = () => {
  const [loaded, setLoaded] = useState(false);
  const [i18n, setI18n] = useState(null);

  useEffect(() => {
    async function loadI18nAndSetLanguage() {
      try {
        // Load i18n and set it into state
        const i18nModule = await i18nPromise;

        // Ensure that the i18n instance is correctly initialized and supports changeLanguage
        if (i18nModule?.default?.changeLanguage) {
          setI18n(i18nModule.default); // Assign the default i18n instance

          const selectedLanguage =
            window.location.href.split("/")[3] === "my-mm" ? "my" : "en";
          i18nModule.default.changeLanguage(selectedLanguage); // Change language dynamically

          setLoaded(true); // Set loaded state to true once i18n is initialized
        } else {
          throw new Error("i18n module does not have changeLanguage method.");
        }
      } catch (error) {
        console.error("Error loading i18n:", error);
      }
    }

    loadI18nAndSetLanguage();
  }, []); // Empty dependency array means this runs once after mount

  if (!loaded) {
    return <div>Loading...</div>; // Display loading screen until i18n is initialized
  }

  return (
    <>
      {/* Render VConsole only when it's loaded */}
      <Suspense fallback={<div>Loading VConsole...</div>}>
        {isAuthenticated() &&
          window.location.href.includes("miniapp") &&
          process.env.REACT_APP_TRACK_RELEASE_STAGE === "development" && (
            <VConsole />
          )}
      </Suspense>

      <ApolloProvider client={clientV3()}>
        <App />
      </ApolloProvider>
    </>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  document.querySelector(".loading-screen").style.display = "none";
});

// Add error listener
window.addEventListener("error", (event) => {
  if (event.message.includes("t.maskResult is not a function")) {
    console.warn("Suppressed error:", event.message);
    event.preventDefault(); // Prevent the error from showing in the console
  }
});

root.render(<AppWrapper />);

reportWebVitals();
